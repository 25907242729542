<template>
  <div class="content">
    <div class="control is-flex">
      <h1 class="title is-1">Ingredients <span class="tag is-large is-primary">Total: {{ ingredients.length }}</span></h1>
      <b-button outlined type="is-primary" class="is-pulled-right" id="add-button" icon-left="plus" @click="createNewIngredient">Add</b-button>
    </div>
    <b-table
      :data="ingredients"
      :hoverable="true"
      :striped="true"
      @click="openIngredient" >

      <b-table-column field="name" label="Name" sortable searchable v-slot="props">
          {{ props.row.name }}
      </b-table-column>
      <b-table-column field="aisle" label="Aisle" sortable searchable v-slot="props">
          {{ props.row.aisle.name }}
      </b-table-column>
      <b-table-column field="created_at" width=60 label="Created" sortable centered v-slot="props">
          <span class="tag is-primary">
              {{ new Date(props.row.created_at).toLocaleDateString() }}
          </span>
      </b-table-column>
      <b-table-column field="delete" width=40 centered v-slot="props">
        <b-button type="is-danger" size="is-small"  icon-right="delete" @click.stop="deleteIngredient(props.row)" />
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'IngredientListView',
  data() {
    return {
      ingredients: []
    }
  },
  firestore: {
    ingredients: db.collection('ingredients').orderBy("created_at", "desc")
  },
  methods: {
    createNewIngredient() {
      db.collection("ingredients").add({
          created_at: Date.now(),
          name: null,
          aisle: null,
          tips: []
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.$router.push({ name: 'IngredientView', params: { id: docRef.id } })
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    },
    openIngredient(ingredient) {
      this.$router.push({ name: 'IngredientView', params: { id: ingredient.id } })
    },
    deleteIngredient(ingredient) {
        console.log(ingredient)
        this.$buefy.dialog.confirm({
            title: 'Deleting ingredient',
            message: `Are you sure you want to delete ingredient: <b><i>"${ingredient.name}"</i></b>? <br /><br />This action cannot be undone.`,
            confirmText: 'Delete Ingredient',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              db.collection("ingredients").doc(ingredient.id)
              .delete()
              .then(() => {
                this.$buefy.toast.open({type: 'is-success', message: `Ingredient successfully deleted!`})
              }).catch((error) => {
                this.$buefy.toast.open({type: 'is-danger', message: `Error deleting ingredient: ${error}`})
              });
            }
        })
    }
  }
}

</script>

<style scoped>
.title {
  flex-grow: 1;
}
.control {
  align-items: center;
}
</style>
